'use client';
import { createContext } from '../../utils/component.context';
export const [BadgeContextProvider, useBadgeContext] = createContext({
    name: 'BadgeContext',
    strict: true,
    hookName: 'useBadgeContext',
    providerName: 'BadgeProvider',
    defaultValue: {
        size: 'xs',
        variant: 'neutral',
    },
});
