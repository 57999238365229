export * from './community';
export * as CAREERS from './careers';

export const COOKIE_POLICY_URL =
  'https://zealy.notion.site/ZEALY-COOKIE-POLICY-3d4051c43a394e0bb6a9a66707889ece';
export const TERMS_AND_CONDITIONS_URL =
  'https://zealy.notion.site/TERMS-OF-USE-OF-ZEALY-eee431138558434eae3d40f7560bdd41';
export const PRIVACY_URL =
  'https://zealy.notion.site/PRIVACY-POLICY-ZEALY-31d0d35aba934beba319b4c057555a46';
export const COMMUNITY_URL = 'https://zealy.io/c/welcometozealy/questboard';
export const COMMUNITY_URL_WEB2 = 'https://zealy.io/c/showcase/questboard';

export const DOCUMENTATION_URL = '/docs';
export const USER_CALL_LINK = 'https://calendar.app.google/pqaU9ziizfTUDzJc6';
