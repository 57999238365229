'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import { BadgeContextProvider, useBadgeContext } from './Badge.context';
import { Children, cloneElement, forwardRef } from 'react';
import { badgeStyles } from './Badge.styles';
import { cn } from '../../utils/cn';
const Root = forwardRef(({ variant = 'neutral', size = 'xs', blurEffect, children, className, ariaLabel, ...props }, ref) => {
    const context = { variant, size };
    return (_jsx(BadgeContextProvider, { value: context, children: _jsx("span", { ref: ref, "data-scope": "Badge", "data-part": "root", "aria-label": ariaLabel, className: cn(badgeStyles.root({ variant, size, blurEffect }), className), ...props, 
            // We use role="status" for badge components
            // https://www.w3.org/WAI/WCAG22/Techniques/aria/ARIA22
            role: "status", children: children }) }));
});
const Label = forwardRef(({ children, className, ...props }, ref) => {
    const { variant, size } = useBadgeContext();
    return (_jsx("span", { ref: ref, "data-scope": "Badge", "data-part": "label", className: cn(badgeStyles.label({ variant, size }), className), ...props, children: children }));
});
const Description = forwardRef(({ children, className, ...props }, ref) => {
    const { variant, size } = useBadgeContext();
    return (_jsx("span", { ref: ref, "data-scope": "Badge", "data-part": "description", className: cn(badgeStyles.description({ variant, size }), className), ...props, children: children }));
});
const Icon = forwardRef(({ children, className, ...props }, ref) => {
    const { variant, size } = useBadgeContext();
    const icon = Children.only(children);
    return cloneElement(icon, {
        ref,
        className: cn(badgeStyles.icon({ variant, size }), icon.props.className, className),
        'data-scope': 'Badge',
        'data-part': 'description',
        ...props,
    });
});
export const BadgeAnatomy = {
    Root,
    Label,
    Description,
    Icon,
};
