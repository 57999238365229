import { cva } from 'class-variance-authority';
const root = cva('flex flex-row justify-center items-center box-content rounded-badge-rounded flex-shrink-0', {
    variants: {
        blurEffect: {
            true: 'backdrop-blur',
        },
        size: {
            xxs: 'gap-badge-xs px-badge-xxs h-badge-xxs min-w-badge-xxs',
            xs: 'gap-badge-xs px-badge-xs h-badge-xs min-w-badge-xs',
            sm: 'gap-badge-sm px-badge-sm h-badge-sm min-w-badge-sm',
            md: 'gap-badge-md px-badge-md h-badge-md min-w-badge-md',
        },
        variant: {
            disabled: 'bg-badge',
            neutral: 'bg-badge',
            positive: 'bg-badge-positive',
            negative: 'bg-badge-negative',
            brand: 'bg-badge-brand',
            info: 'bg-badge-info',
        },
    },
    defaultVariants: {
        size: 'xs',
        variant: 'neutral',
    },
});
const label = cva('whitespace-nowrap min-w-0 truncate', {
    variants: {
        size: {
            xxs: 'badge-xs',
            xs: 'badge-xs',
            sm: 'badge-sm',
            md: 'badge-md',
        },
        variant: {
            disabled: 'text-badge-neutral-disabled',
            neutral: 'text-badge-neutral-primary',
            positive: 'text-badge-positive-primary',
            negative: 'text-badge-negative-primary',
            brand: 'text-badge-brand-primary',
            info: 'text-badge-info-primary',
        },
    },
    defaultVariants: {
        size: 'xs',
        variant: 'neutral',
    },
});
const description = cva('whitespace-nowrap', {
    variants: {
        size: {
            xxs: 'badge-xs',
            xs: 'badge-xs',
            sm: 'badge-sm',
            md: 'badge-md',
        },
        variant: {
            disabled: 'text-badge-neutral-disabled',
            neutral: 'text-badge-neutral-secondary',
            positive: 'text-badge-positive-secondary',
            negative: 'text-badge-negative-secondary',
            brand: 'text-badge-brand-secondary ',
            info: 'text-badge-info-secondary',
        },
    },
    defaultVariants: {
        size: 'xs',
        variant: 'neutral',
    },
});
const icon = cva('Badge', {
    variants: {
        size: {
            xxs: 'w-badge-icon-xs h-badge-icon-xs',
            xs: 'w-badge-icon-xs h-badge-icon-xs',
            sm: 'w-badge-icon-sm h-badge-icon-sm',
            md: 'w-badge-icon-md h-badge-icon-md',
        },
        variant: {
            disabled: 'text-badge-neutral-disabled',
            neutral: 'text-badge-neutral-primary',
            positive: 'text-badge-positive-primary',
            negative: 'text-badge-negative-primary',
            brand: 'text-badge-brand-primary',
            info: 'text-badge-info-primary',
        },
    },
    defaultVariants: {
        size: 'xs',
        variant: 'neutral',
    },
});
export const badgeStyles = {
    root,
    label,
    icon,
    description,
};
